.nota-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;
}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.nota-container-text {
	margin-bottom: 0rem;
	width: 100%;
	position: relative;
}

.nota-title {
	font-size: 1.25rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
	width: 100%;
}

.nota-summary {
	display: none;
	font-size: 1rem;
	line-height: 1.375;
	color: var(--gray-text);
}
.background{
	background: #fff000;
	padding: 20px 10px;
}
.nota-autor {
	margin-top: 1rem;
	color: var(--medium-black);
    font-size: .8rem;
}
.nota-autor a{
	font-weight: 700;
}
.display-none {
	display: none;
}
.blue-txt { 
	color: var(--sky-blue);
	font-size: 1.25rem;
	margin-right: 5px;
	font-weight: 400;
	
}
.nota-container-text h2 {
	margin:0;
	width: 100%;
	line-height: 1;
}
.patrocinado{
	display: flex;
    align-items: center;
    position: absolute;
	-moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: .5rem;
    background-color: #000000b8;    
    color: var(--white);
    padding: .5rem .5rem;
    border-radius: 4px;
    font-size: .55rem;
    text-transform: uppercase;
    letter-spacing: 0;
    /*bottom: -1.5rem;*/
    font-weight: 400;
}
.contenedor-nota-horizontal {
	border-top: 0px solid var(--shadow-gray);
	padding: 0;
}

.img-container {
	/*height: 200px;
	margin-bottom: 5px;
	width: 300px;
    overflow: hidden; */
	width: 300px;
    height: auto;
    float: left;
	overflow: hidden;
    margin: 0 1rem 0 0;
	}

@media (max-width: 1024px) {
	.contenedor-nota-horizontal {
		border-top: 0px solid var(--shadow-gray);
		padding: 0 0 0 0;
	}

}
@media (max-width: 768px) {
	.img-container{
		/* min-height: 0px; */
		width: 100%;
		height: auto;
	}
	.nota-container-text {
		margin-bottom: 0rem;
	}
	h2 .nota-title {
	font-size: 1.25rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
	}
	.nota-summary {
		margin: 0rem 0;
	}
	.nota-autor {
		margin-top: 0.6rem;
	}

	.contenedor-nota-horizontal {
		border-bottom: 0;
		border-top: 0;
		height: min-content;
		padding: 0;
		margin-bottom: 0rem;
	}
	
	
	
}